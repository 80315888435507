import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import LayoutRoot from '../components/common/LayoutRoot'
import LayoutMain from '../components/common/LayoutMain'
// import OgImage from '../assets/images/og-image.jpg'
import OgImage from '../assets/images/promaid/homecare-promiad-2.png'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC = ({ children }) => (
  // <StaticQuery
  //   query={graphql`
  //     query IndexLayoutQuery {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //         }
  //       }
  //     }
  //   `}
  //   render={(data: StaticQueryProps) => (
  <LayoutRoot>
    <Helmet
      title="HomeCare by ProMaid แม่บ้านมืออาชีพ"
      meta={[
        {
          name: 'description',
          content:
            'บริการแม่บ้านทำความสะอาดรายชั่วโมง รายวัน จองง่ายๆ ไม่ต้องโหลดแอป ทำความสะอาดบ้าน คอนโด หรือบริษัท ได้มาตรฐานจากประสบการณ์กว่า 18 ปี'
        },
        { name: 'keywords', content: 'ProMaid, แม่บ้านออนไลน์, homecare' },
        { property: 'og:title', content: 'HomeCare by ProMaid แม่บ้านมืออาชีพ' },
        { property: 'og:image', content: OgImage }
      ]}
    />
    <LayoutMain>{children}</LayoutMain>
  </LayoutRoot>
  //   )}
  // />
)

export default IndexLayout
